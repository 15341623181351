<template>
  <v-app>
    <v-container>
      <!-- header -->
      <v-row class="align-center">
        <v-col cols="12">
          <div class="subtitle-1">Thêm bài nghe</div>
        </v-col>
        <v-col cols="4">
          <div>
            <vuetify-audio
                :file="materialDetail.audio.src"
                color="success"
                downloadable
                autoPlay="false"
            ></vuetify-audio>
          </div>
        </v-col>
        <v-col cols="4">
          <v-file-input
              class="pl-1"
              prepend-icon="mdi-soundcloud"
              v-model="fileUpload"
              accept=".mp3,audio/*"
              label="Tải file nghe"

              @change="onChangeFileUpload"
          ></v-file-input>
        </v-col>
      </v-row>
      <!-- fill answer block -->
      <v-row class="justify-center align-center">
        <div>
          <HnrButton
              class="rounded-pill white--text"
              :color="'#2B2B59'"
              :text="'Lưu'"
              @hnrClickEvent="submitMaterial"
              :width="'200px'"
          />
        </div>

      </v-row>
      <v-row>
        <div class="h5">{{ titleQuestion }}</div>
      </v-row>
      <v-row class="align-center justify-center">
        <HnrButton
            class="rounded-pill white--text"
            :color="'#2B2B59'"
            :text="addQuestionText"
            @hnrClickEvent="addQuestion"
            :width="'200px'"
        />
      </v-row>
      <v-row>
        <v-col>
          <div
              v-for="(question,index) in questionList"
              :key="question.id"
              class="question-group"
          >
            <v-row class="ma-20" no-gutters>
              <v-col>
                <div class="font-weight-bold ">
                  Câu hỏi {{ index + 1 }} :
                  <h5>Tiêu đề</h5>
                  <div class="text-h5"
                       v-html="question.content"
                  ></div>
                </div>

                <div class="font-weight-bold ">
                  <h5>Bản dịchs</h5>
                  <div class="text-body-1"
                       v-html="question.content_translate"
                  ></div>
                </div>
              </v-col>
              <v-col class="justify-end text-end">
                <v-btn @click="editQuestion(question)" width="100px">
                  <v-icon small class="mr-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteQuestion(question)" width="100px">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- check answer  -->
            <v-row>
              <h4>Đáp án</h4>
              <ul class="pl-10" id="example-1">
                <li v-for="answer in question.answers" :key="answer.id">
                  <div v-if="question.question_type == 1" class="my-3">
                    <img :src="answer.url" width="100px"> <span
                      v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                  </div>
                  <div v-if="question.question_type == 2">
                    <div :class="[answer.check==1 || answer.check == -1 ? 'green--text' : '']">
                      {{ answer.content }} ({{ answer.description }})
                    </div>
                    <span v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                  </div>
                </li>
              </ul>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import {
  UPDATE_EXERCISE_MATERIAL,
  GET_EXERCISE_DETAIL,
  GET_EXERCISE_CONTENT,
} from "@/store/exercises.module";
import {mapGetters} from "vuex";
import {
  UPLOAD_EXERCISE_MATERIAL,
  GET_LISTEN_MATERIAL_DETAIL,
  DELETE_EXERCISE_CONTENT
} from "@/store/exercises.module";


export default {
  components: {
    HnrButton : ()=>import("@/components/elements/hnr-button"),
    VuetifyAudio: () => import("vuetify-audio"),
  },
  data: () => ({
    newIndex: -1,
    headers: [
      {text: "Đáp án", value: "file"},
      {text: "Dịch đáp án", value: "description"},
      {text: "Chọn đáp án đúng", value: "check"},
      {text: "Thao tác", value: "actions", sortable: false},
    ],
    headers1: [
      {text: "Đáp án", value: "file"},
      {text: "Chọn đáp án đúng", value: "check"},
      {text: "Thao tác", value: "actions", sortable: false},
    ],
    writeAnswer: 0,
    isSelectBox: true,
    isUpdate: false,
    imageError: "",
    show: false,
    images: [],
    img: '',
    fileUpload: {},
    audio: {
      src: "",
    },
    addQuestionText: 'Thêm câu hỏi mới',
    titleQuestion: 'Câu hỏi',
  }),
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      materialDetail: "materialDetail",
      questionList: "questionList",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_EXERCISE_CONTENT, {
      exercise_slug: this.$route.query.exercise_slug,
      index: this.$route.query.index,
    });
    if (this.$route.query.exercise_slug) {
      this.getReadingMaterial(this.$route.query.exercise_slug)
    }
  },
  watch: {},
  methods: {
    addQuestion() {
      this.$router.push({
        name: "exercise-listen-add",
        query: {
          exercise_slug: this.exercise.slug,
          index: this.$route.query.index ? this.$route.query.index : null
        },
      });
    },
    editQuestion(question) {
      this.$router.push({
        name: "exercise-listen-add",
        query: {
          exercise_slug: this.exercise.slug,
          question_id: question.id,
          index: this.$route.query.index
        },
      });
    },
    onChangeFileUpload() {
      let img = this.fileUpload
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", img);
      this.$store.dispatch(UPLOAD_EXERCISE_MATERIAL, formData).then(res => {
        if (res.data.status == 1) {
          this.materialDetail.audio.src = res.data.data
          this.$forceUpdate();
        } else {
          this.$toasted.error('File chưa đúng định dạng', {
            position: "top-right",
            duration: 3000
          })
        }
      });
    },
    clearImage() {
    },
    getReadingMaterial() {
      let payload = {
        exercise_slug: this.$route.query.exercise_slug,
        index: this.$route.query.index,
      }
      this.$store.dispatch(GET_LISTEN_MATERIAL_DETAIL, payload)
    },
    addAnswer() {
      this.question.answers.push({
        id: --this.newIndex,
        file: null,
        content: null,
        url: null,
        check: 0,
      });
    },
    deleteItem(item) {
      const index = this.question.answers.indexOf(item);
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.question.answers.splice(index, 1);
    },
    getMissingSentenceContent(value) {
      this.question.filled_dialog.missing_sentence = value;
    },
    getCompleteSentenceContent(value) {
      this.question.filled_dialog.complete_sentence = value;
    },
    countRightAnswer(answers) {
      let count = 0;
      answers.forEach((item) => {
        if (item.check) {
          count++;
        }
      })
      return count;
    },
    submitMaterial() {
      if (!this.materialDetail.audio.src) {
        this.$toasted.error('Chưa nhập bài nghe', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (this.materialDetail.audio.src) {
        this.$store.dispatch(UPDATE_EXERCISE_MATERIAL, {
          exercise: this.exercise,
          audio: this.materialDetail.audio.src,
          material: this.materialDetail,
          index: this.$route.query.index
        }).then((data) => {
          if (data.status) {
            this.$toasted.success('Upload tài liệu thành công', {
              position: "top-right",
              duration: 3000
            })
          }
        });
      }

    },
    deleteQuestion(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE_CONTENT, {
            question_id: item.id,
            exercise: this.exercise,
          })
          .then(() => {
            this.$store.dispatch(GET_EXERCISE_CONTENT, {
              exercise_slug: this.exercise.slug,
              index: this.$route.query.index,
            });
          });
    },
  },
};
</script>
